
:root {
    --red: #B22222;
    --border-red: #B22222;
    --redShadow: #701414;
    --rShadow:
    0 -3px var(--red),
    3px 0 var(--red),
    0 3px var(--red),
    -3px 0 var(--red),
    0 -6px var(--border-red),
    3px -3px var(--border-red),
    6px 0 var(--border-red),
    0 6px var(--border-red),
    -3px 3px var(--border-red),
    -6px 0 var(--border-red),
    -3px -3px var(--border-red),
    3px 3px var(--border-red),
    0px 9px var(--redShadow),
    3px 6px var(--redShadow),
    6px 3px var(--redShadow),
    0px 9px var(--redShadow),
    -3px 6px var(--redShadow),
    -6px 3px var(--redShadow);
    --raShadow:
    0 -3px var(--red),
    3px 0 var(--red),
    0 3px var(--red),
    -3px 0 var(--red),
    0 -6px var(--border-red),
    3px -3px var(--border-red),
    6px 0 var(--border-red),
    0 6px var(--border-red),
    -3px 3px var(--border-red),
    -6px 0 var(--border-red),
    -3px -3px var(--border-red),
    3px 3px var(--border-red),
    0 6px var(--redShadow),
    3px 3px var(--redShadow),
    6px 0 var(--redShadow),
    0 6px var(--redShadow),
    -3px 3px var(--redShadow),
    -6px 0 var(--redShadow);
    --green: green;
    --border-green: green;
    --greenShadow: #006700;
    --gShadow: 0 -3px var(--green),
    3px 0 var(--green),
    0 3px var(--green),
    -3px 0 var(--green),
    0 -6px var(--border-green),
    3px -3px var(--border-green),
    6px 0 var(--border-green),
    0 6px var(--border-green),
    -3px 3px var(--border-green),
    -6px 0 var(--border-green),
    -3px -3px var(--border-green),
    3px 3px var(--border-green),
    0px 9px var(--greenShadow),
    3px 6px var(--greenShadow),
    6px 3px var(--greenShadow),
    0px 9px var(--greenShadow),
    -3px 6px var(--greenShadow),
    -6px 3px var(--greenShadow);
    --gaShadow:
    0 -3px var(--green),
    3px 0 var(--green),
    0 3px var(--green),
    -3px 0 var(--green),
    0 -6px var(--border-green),
    3px -3px var(--border-green),
    6px 0 var(--border-green),
    0 6px var(--border-green),
    -3px 3px var(--border-green),
    -6px 0 var(--border-green),
    -3px -3px var(--border-green),
    3px 3px var(--border-green),
    0 6px var(--greenShadow),
    3px 3px var(--greenShadow),
    6px 0 var(--greenShadow),
    0 6px var(--greenShadow),
    -3px 3px var(--greenShadow),
    -6px 0 var(--greenShadow);
    --white: white;
    --whiteSH: #bbbbbb;
    --wShadow: 0 -3px var(--white),
    3px 0 var(--white),
    0 3px var(--white),
    -3px 0 var(--white),
    0 -6px var(--white),
    3px -3px var(--white),
    6px 0 var(--white),
    0 6px var(--white),
    -3px 3px var(--white),
    -6px 0 var(--white),
    -3px -3px var(--white),
    3px 3px var(--white),
    0px 9px var(--whiteSH),
    3px 6px var(--whiteSH),
    6px 3px var(--whiteSH),
    0px 9px var(--whiteSH),
    -3px 6px var(--whiteSH),
    -6px 3px var(--whiteSH);
    --waShadow: 0 -3px var(--white),
    3px 0 var(--white),
    0 3px var(--white),
    -3px 0 var(--white),
    0 -6px var(--white),
    3px -3px var(--white),
    6px 0 var(--white),
    0 6px var(--white),
    -3px 3px var(--white),
    -6px 0 var(--white),
    -3px -3px var(--white),
    3px 3px var(--white),
    0 6px var(--whiteSH),
    3px 3px var(--whiteSH),
    6px 0 var(--whiteSH),
    0 6px var(--whiteSH),
    -3px 3px var(--whiteSH),
    -6px 0 var(--whiteSH);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Press Start 2P', cursive;
    user-select: none;
    outline: none;
}
::-webkit-scrollbar {
    width: 0;
}
html{
    background: #222;
    color: white;
}

.Clicker{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 20px;
}
.score{
    display: flex;
    width: 100%;
    /*align-items: flex-end;*/
    margin-bottom: 15px;
    /*align-self: flex-end;*/
    /*font-size: clamp(12px, (1vh + 1vw) * 0.83, 25px);*/
}
.score-blok{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}
.score-blok:first-child{

}
.score div:not(last-child){
    margin-bottom: 10px;
}

.buttons{
    margin-top: 15px;
    padding: 0 0 20px 0;
    display: flex;
    width: clamp(300px, calc(100% - 40px), 720px);
    justify-content: space-between;
}
.b{
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(120px, (1vh + 1vw) * 5, 150px);
    text-align: center;
    line-height: 1.5;
    letter-spacing: 1px;
    padding: 8px 0;
    cursor: pointer;
    font-size: clamp(16px, (1vh + 1vw) * 0.66, 20px);
}
.b:active{
    transform: scale(0.98) translateY(3px);
}
.next{
    background: var(--green);
    box-shadow: var(--gShadow);
}
.end{
    background: var(--red);
    box-shadow: var(--rShadow);
}
.next:active{
    box-shadow: var(--gaShadow);
}
.end:active{
    box-shadow: var(--raShadow);
}

.click{
    display: flex;
    flex-direction: column;
    width: clamp(320px, calc(100% - 40px), 720px);
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    font-size: clamp(18px, (1vh + 1vw) * 1.006, 32px);
}
.click div{
    min-height: 32px;
}
.countInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
}
.clck{
    padding: 20px 0;
    width: 200px;
    font-weight: bold;
    font-size: clamp(18px, (1vh + 1vw) * 0.83, 25px);
    margin: clamp(10px, (1vh + 1vw), 30px) 0 clamp(30px, (1vh + 1vw) * 2, 60px);
}

.endText{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: clamp(15px, (1vh + 1vw), 30px);
    text-align: center;
    font-size: clamp(22px, (1vh + 1vw) * 1.5, 45px);
    line-height: 1.5;
}
.count,
.timer {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: clamp(30px, (1vh + 1vw) * 2, 60px);
}
.text{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.3;
}
.count,
.text{
    margin: auto 0;
}
.you,
.topScore,
.topDay{
    display: flex;
    flex-direction: column;
    /*justify-content: flex-end;*/
    text-align: right;
    line-height: 1.7;
    margin-bottom: 10px;
    font-size: clamp(10px, (1vh + 1vw) * 0.83, 25px);
}
.startInput{
    font-size: clamp(25px, (1vh + 1vw) * 1.66, 50px);
    padding: 20px 5px;
    background: none;
    border: none;
    width: 100%;
    margin-bottom: 60px;
    color: white;
    text-align: center;
}
.go{
    font-size: clamp(30px, (1vh + 1vw) * 2, 60px);
    margin-bottom: 60px;
}
.yn{
    font-size: clamp(30px, (1vh + 1vw) * 2, 60px);
    margin-bottom: clamp(30px, (1vh + 1vw) * 2, 60px);
    text-align: center;
}
.you{
    /*justify-content: flex-start;*/
    text-align: left;
}
.all-button{
    font-size: clamp(10px, (1vh + 1vw) * 0.83, 25px);
    border: 1px solid white;
    box-shadow: var(--wShadow);
    /*padding: 3px 12px;*/
    /*display: flex;*/
    width: 70%;
    max-width: 300px;
    text-align: center;
    line-height: 1.3;
    background: white;
    color: #222222;
    cursor: pointer;
}
.all-button:active{
    transform: scale(0.98) translateY(3px);
    box-shadow: var(--waShadow);
}
.showAllScore{
    display: flex;
    flex-direction: column;
    width: clamp(300px, calc(100% - 40px),800px);
    height: 100%;
    overflow-y: auto;
    /*background: darkseagreen;*/
}
.shsr{
    display: flex;
    width: 100%;
    height: 30px;
    padding: clamp(10px, (1vh + 1vw) * 0.83, 25px) 0;
    border-bottom: 1px solid white;
}
.shsr:first-child{
    border: none;
    padding-bottom: clamp(15px, (1vh + 1vw), 30px);
    position: sticky;
    top: 0;
    background: #222222;
}
.shsr .plase{
    flex: 3;
    text-align: center;
    font-size: clamp(10px, (1vh + 1vw) * 0.83, 25px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.shsr .winName{
    flex: 8;
    text-align: center;
    font-size: clamp(10px, (1vh + 1vw) * 0.83, 25px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.shsr .winScore{
    flex: 3;
    text-align: center;
    font-size: clamp(10px, (1vh + 1vw) * 0.83, 25px);
    display: flex;
    align-items: center;
    justify-content: center;
}